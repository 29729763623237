import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { DataService } from './data.service';
import { NavbarComponent } from './navbar/navbar.component';
import { Page404Component } from './page404/page404.component';
import { HseqComponent } from './hseq/hseq.component';
import { DirectorioComponent } from './directorio/directorio.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { RecursosComponent } from './recursos/recursos.component'
import { TooltipModule } from 'ng2-tooltip-directive';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    Page404Component,
    HseqComponent,
    DirectorioComponent,
    GaleriaComponent,
    RecursosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgScrollbarModule,
    TooltipModule
  ],
  providers: [DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
