import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.css']
})
export class DirectorioComponent implements OnInit {
  personal = [
    {
      nombre: 'Gloria',
      apellidos: 'Lee',
      area: 'Presidencia',
      cargo: 'Presidenta',
      email: 'gloria.lee@asiltda.co',
      ext: '134'
    },
    {
      nombre: 'Adriana',
      apellidos: 'Arango',
      area: 'Presidencia',
      cargo: 'Gerente General',
      email: 'aarango@asiltda.co',
      ext: '112'
    },
    {
      nombre: 'Octavio',
      apellidos: 'Arango',
      area: 'Presidencia',
      cargo: 'Dir. Animación',
      email: 'octarango@asiltda.co',
      ext: '106'
    },
    {
      nombre: 'Felipe',
      apellidos: 'Villamil',
      area: 'Presidencia',
      cargo: 'Dir. de Tecnología',
      email: 'felipe.villamil@asiltda.co',
      ext: '139'
    },
    {
      nombre: 'Ivan',
      apellidos: 'Murcia',
      area: 'Presidencia',
      cargo: 'Dir. Administrativo y Financiero',
      email: 'ivan.murcia@asiltda.co',
      ext: '126'
    },
    {
      nombre: 'Alejandro',
      apellidos: 'Velásquez',
      area: 'Desarrollo Humano',
      cargo: 'Dir. de Desarrollo Humano',
      email: 'alejandro.velasquez@asiltda.co',
      ext: '124'
    },
    {
      nombre: 'Juan Pablo',
      apellidos: 'Duque',
      area: 'Compras y Logistica',
      cargo: 'Auxiliar de Compras y Logistica',
      email: 'juan.duque@asiltda.co',
      ext: '116'
    },
    {
      nombre: 'Nicolás',
      apellidos: 'Parra',
      area: 'Compras y Logistica',
      cargo: 'Coord. de Compras y Logistica',
      email: 'nparra@asiltda.co',
      ext: '105'
    },
    {
      nombre: 'Marcela',
      apellidos: 'Roa',
      area: 'Tesorería',
      cargo: 'Coord. de Tesorería',
      email: 'marcela.roa@asiltda.co',
      ext: '113'
    },
    {
      nombre: 'Lina',
      apellidos: 'Jiménez',
      area: 'HSEQ',
      cargo: 'Coord. de HSEQ',
      email: 'lina.jimenez@asiltda.co',
      ext: '140'
    },
    {
      nombre: 'Jairo',
      apellidos: 'Vergara',
      area: 'Técnica',
      cargo: 'Dirección Técnica',
      email: 'dir.tecnica@asiltda.co',
      ext: '132'
    },
    {
      nombre: 'Paola',
      apellidos: 'Ruíz',
      area: 'Contabilidad',
      cargo: 'Analista Contable',
      email: 'paola.ruiz@asiltda.co',
      ext: '131'
    },
    {
      nombre: 'Mayra',
      apellidos: 'Mena',
      area: 'Técnica',
      cargo: 'Profesional de Apoyo',
      email: 'mayra.mena@asiltda.co',
      ext: '110'
    },
    {
      nombre: 'Luis',
      apellidos: 'Becerra',
      area: 'Geomática',
      cargo: 'Dir. de Geomática',
      email: 'eduardo.becerra@asiltda.co',
      ext: '111'
    },
    {
      nombre: 'Fernando',
      apellidos: 'Rivera',
      area: 'Geomática',
      cargo: 'Profesional SIG',
      email: 'fernando.rivera@asiltda.co',
      ext: '111'
    },
    {
      nombre: 'Recepción',
      apellidos: '',
      area: 'Recepción',
      cargo: 'Atención Social Integral',
      email: 'www.asiltda.co',
      ext: '100'
    }
  ]


  personal2 = [
    {
      nombre: 'Sonia',
      apellidos: 'Chivatá',
      area: 'Presidencia',
      cargo: 'Contadora',
      email: 'sonia.chivata@asiltda.co',
      ext: '115'
    },
    {
      nombre: 'Luis',
      apellidos: 'Parrado',
      area: 'Tecnología',
      cargo: 'Coord. Desarrollo de Software',
      email: 'luis.parrado@asiltda.co',
      ext: '106'
    },
    {
      nombre: 'David',
      apellidos: 'Botero',
      area: 'Tecnología',
      cargo: 'Diseñador Gráfico',
      email: 'david.botero@asiltda.co',
      ext: '143'
    },
    {
      nombre: 'Mario',
      apellidos: 'Lee',
      area: 'Tecnología',
      cargo: 'Asistente de Tecnología',
      email: 'mario.lee@asiltda.co',
      ext: '143'
    },
    {
      nombre: 'Jesus',
      apellidos: 'Mendoza',
      area: 'Tecnología',
      cargo: 'Auxiliar de Tecnología',
      email: 'jesus.mendoza@asiltda.co',
      ext: '149'
    },
    {
      nombre: 'Diana',
      apellidos: 'Campos',
      area: 'Técnica',
      cargo: 'Coord. de Proyectos',
      email: 'diana.campos@asiltda.co',
      ext: '130'
    },
    {
      nombre: 'Edison',
      apellidos: 'Angel',
      area: 'Técnica',
      cargo: 'Coord. de Proyectos',
      email: 'eangel@asiltda.co',
      ext: '144'
    },
    {
      nombre: 'Alvaro',
      apellidos: 'Ñañéz',
      area: 'Técnica',
      cargo: 'Coord. de Proyectos',
      email: 'alvaro.nanez@asiltda.co',
      ext: '144'
    },
    {
      nombre: 'Daniela',
      apellidos: 'Gutierrez',
      area: 'Comercial',
      cargo: 'Profesional Propuestas',
      email: 'daniela.gutierrez@asiltda.co',
      ext: '105'
    },
    {
      nombre: 'Angy',
      apellidos: 'Vega',
      area: 'HSEQ',
      cargo: 'Auxiliar de HSEQ',
      email: 'auxiliar.hseq@asiltda.co',
      ext: '142'
    },
    {
      nombre: 'Giovanni',
      apellidos: 'Goméz',
      area: 'Técnica',
      cargo: 'Profesional de Apoyo',
      email: 'ggomez@asiltda.co',
      ext: '104'
    },
    {
      nombre: 'Sandra',
      apellidos: 'Blanco',
      area: 'Técnica',
      cargo: 'Auxiliar Técnica',
      email: 'auxiliar.tecnica@asiltda.co',
      ext: '104'
    },
    {
      nombre: 'Santiago',
      apellidos: 'Carvajal',
      area: 'Técnica',
      cargo: 'Profesional de Apoyo',
      email: 'santiago.carvajal@asiltda.co',
      ext: '110'
    },
    {
      nombre: 'Jenny',
      apellidos: 'Mateus',
      area: 'Técnica',
      cargo: 'Profesional Social',
      email: 'jenny.mateus@asiltda.co',
      ext: '110'
    },
    {
      nombre: 'Angélica',
      apellidos: 'León',
      area: 'Servicios Generales',
      cargo: 'Operario de Servicios Generales',
      email: 'www.asiltda.co',
      ext: '104'
    },
    {
      nombre: 'Sala de Juntas',
      apellidos: 'Primer Piso',
      area: 'Sala de Juntas',
      cargo: 'Sala de Juntas',
      email: 'www.asiltda.co',
      ext: '135'
    }
  ]

  emergencias = [
    {
      contacto: 'SURA',
      categoria: 'Hospitales',
      telefono: '018000511414'
    },
    {
      contacto: 'ARL SURA Bogotá',
      categoria: 'Hospitales',
      telefono: '4897941'
    },
    {
      contacto: 'Bomberos',
      categoria: '',
      telefono: '119'
    },
    {
      contacto: 'Cruz Roja',
      categoria: '',
      telefono: '132'
    },
    {
      contacto: 'Defensa Civil',
      categoria: '',
      telefono: '144'
    },
    {
      contacto: 'Acueducto',
      categoria: '',
      telefono: '116'
    },
    {
      contacto: 'Codensa',
      categoria: '',
      telefono: '115'
    },
    {
      contacto: 'Movilidad Bogotá',
      categoria: '',
      telefono: '195'
    },
    {
      contacto: 'Policia Nacional',
      categoria: '',
      telefono: '112'
    },
    {
      contacto: 'Gaula Policia',
      categoria: '',
      telefono: '165'
    },
    {
      contacto: 'Cuadrante la Castellana',
      categoria: '',
      telefono: '3002028974'
    },
    {
      contacto: 'Nicolás Parra',
      categoria: 'Brigadista ASI',
      telefono: '3165631850'
    },
    {
      contacto: 'Giovanni Gómez',
      categoria: 'Brigadista ASI',
      telefono: '018000511414'
    },
    {
      contacto: 'Angélica León',
      categoria: 'Brigadista ASI',
      telefono: '3015323235'
    },
    {
      contacto: 'Lina Marcela Jiménez',
      categoria: 'Brigadista ASI',
      telefono: '3102897789'
    },
    {
      contacto: 'Fernando Rivera',
      categoria: 'Brigadista ASI',
      telefono: '3102491241'
    },
    {
      contacto: 'Edison Ángel',
      categoria: 'Brigadista ASI',
      telefono: '3012898529'
    },
    {
      contacto: 'Emergencias',
      categoria: '',
      telefono: '123 / 145'
    },
    {
      contacto: 'Atención a Desastres',
      categoria: '',
      telefono: '111'
    },
    {
      contacto: 'Clínica los Nogales',
      categoria: 'Calle 95 No. 23-61',
      telefono: '5937071'
    },
    {
      contacto: 'Clínica Materno Infantil',
      categoria: 'Aut. Norte No 94-57',
      telefono: '7957214'
    },
    {
      contacto: 'Clínica Médico Dalí',
      categoria: 'Calle 97 No 23-37',
      telefono: '6421600'
    },
    {
      contacto: 'Clínica Barraquer',
      categoria: 'Calle 100 N0 18A-51',
      telefono: '2187077'
    },
    {
      contacto: 'Salud Total',
      categoria: 'Cra 45 con calle 102',
      telefono: '3003016584'
    },
    {
      contacto: 'Sanitas EPS castellana',
      categoria: 'Cra 49b No.94-40',
      telefono: '3003016584'
    },
    {
      contacto: 'Salud Total',
      categoria: 'Autopista Norte No 93',
      telefono: '5895391'
    },
    {
      contacto: 'IPS SOA SAS',
      categoria: 'Cra 46 No 91-35',
      telefono: '3133867804'
    }
  ]
  constructor() { }

  ngOnInit() {
  }

}
