import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hseq',
  templateUrl: './hseq.component.html',
  styleUrls: ['./hseq.component.css', './hseq.component.scss']
})
export class HseqComponent implements OnInit {
  disabled = false;
  compact = true;
  shown: 'native' | 'hover' | 'always' = 'hover';
  title = 'Home';

  personal = [
    {
      nombre: 'David',
      apellidos: 'Botero',
      foto: './assets/images/personal/botero.png'
    },
    {
      nombre: 'Paola',
      apellidos: 'Ruiz',
      foto: './assets/images/personal/paola.png'
    }
  ]

  personal2 = [
    {
      nombre: 'Adriana',
      apellidos: 'Arango',
      foto: './assets/images/personal/adriana.png'
    },
    {
      nombre: 'Alejandro',
      apellidos: 'Velásquez',
      foto: './assets/images/personal/alejandro.png'
    }
  ]

  personal3 = [
    {
      nombre: 'Lina',
      apellidos: 'Jiménez',
      foto: './assets/images/personal/lina.png'
    },
    {
      nombre: 'Marcela',
      apellidos: 'Roa',
      foto: './assets/images/personal/marcela.png'
    }
  ]

  personal4 = [
    {
      nombre: 'Juan Pablo',
      apellidos: 'Duque',
      foto: './assets/images/personal/juan.png'
    },
    {
      nombre: 'Juan Pablo',
      apellidos: 'Duque',
      foto: './assets/images/personal/juan.png'
    }
  ]

  personal5 = [
    {
      nombre: 'Giovanni',
      apellidos: 'Gómez',
      foto: './assets/images/personal/gio.png',
      rol: 'Jefe de Brigadas'
    },
    {
      nombre: 'Lina',
      apellidos: 'Jiménez',
      foto: './assets/images/personal/lina.png',
      rol: 'Primeros Auxilios'
    },
    {
      nombre: 'Angélica',
      apellidos: 'León',
      foto: './assets/images/personal/angie.png',
      rol: 'Contra Incendios'
    },
    {
      nombre: 'Nicolas',
      apellidos: 'Parra',
      foto: './assets/images/personal/nicolas.png',
      rol: 'Evaciación (Oficina)'
    },
    {
      nombre: 'Edison',
      apellidos: 'Angel',
      foto: './assets/images/personal/edison.png',
      rol: 'Evacuación (Oficina/Campo)'
    },
    {
      nombre: 'Fernando',
      apellidos: 'Rivera',
      foto: './assets/images/personal/fernando.png',
      rol: 'Evacuacaión (Oficina)'
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
