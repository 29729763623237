import { Component } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', './home.component.scss']
})

export class HomeComponent {
  disabled = false;
  compact = true;
  shown: 'native' | 'hover' | 'always' = 'hover';
  title = 'Home';
  month = ''
  year = null

  news = [
    {
      title: 'Recertificaciones',
      text: `<p>Gracias a la colaboración y al trabajo en equipo estamos orgullosos de informarles que para este 2019 continuamos certificados en ISO 9001, 14001, OHSAS 18001 y logramos la recertificación NORSOCK-S-006 con un puntaje de 94%. Igualmente nos recertificarnos en guía RUC con un puntaje de 93%; finalmente se llevó a cabo la evaluación de la implementación del decreto 1072 del 2015 en un 98%. <p>
      Por este motivo estamos orgullosos y sabemos que este es un reflejo de un esfuerzo conjunto de todos nuestros procesos. Por todo lo anterior extendemos una mención especial al área de HSEQ por liderar estas actividades. Gracias a todos y felicitaciones a todos como TEAM ASI.
      `,
      img: './assets/images/news/recertificaciones.png'
    },
    {
      title: 'Aeronáutica-Civil',
      text: `El día 29 de abril la Aerocivil incluyó a Atención Social Integral (ASI S.A.S.) en la base de datos de RPAS de la Unidad Especial de la Aeronáutica Civil, junto con dos pilotos de RPAS (David Botero y Mario Alejandro Lee) y tres drones. Gracias a esto ASI S.A.S. está autorizada como explotadora de RPAS (drones) con un fin comercial, enfocados en teledetección, fotografía, televisión y video. Esta certificación está sujeta a la circular reglamentaria 002 de la Aerocivil. <br>
      Por este logro agradecemos a todas las partes que estuvieron liderando este gran avance para el crecimiento de la compañía.
      `,
      img: './assets/images/news/aeronautica.png'
    },
    {
      title: 'Caquetá, el departamento con mayor deforestación',
      text: `Aunque a nivel nacional la tala se redujo en un 10% frente a 2017, algunas regiones siguen preocupando al Ideam y al Ministerio de Ambiente. El año pasado se perdieron 197.159 hectáreas de bosque en el país. La cifra, presentada este miércoles por el Ideam y el Ministerio de Ambiente, representa una disminución del 10 % con respecto a la tasa de deforestación registrada en Colombia en 2017. <br> En contra de lo esperado, las acciones del Gobierno lograron evitar la tala de 40.360 hectáreas de bosque natural.`,
      img: './assets/images/news/deforestacion.png'
    }
  ]

  personal = [
    /* {
      nombre: 'Ivan',
      apellidos: 'Murcia',
      cargo: 'Coordinador Administrativo',
      fecha: '06/07/2019',
      dia: '06',
      foto: './assets/images/personal/ivan.png'
    },
    {
      nombre: 'Angelica',
      apellidos: 'León',
      cargo: 'Operario Servicios Generales',
      fecha: '09/07/2019',
      dia: '09',
      foto: './assets/images/personal/angie.png'
    },
    {
      nombre: 'Edison',
      apellidos: 'Angel',
      cargo: 'Coordinador de Proyectos',
      fecha: '14/07/2019',
      dia: '14',
      foto: './assets/images/personal/edison.png'
    },
    {
      nombre: 'Lina',
      apellidos: 'Jimenez',
      cargo: 'Coordinadora HSEQ',
      fecha: '19/07/2019',
      dia: '19',
      foto: './assets/images/personal/lina.png'
    },
    {
      nombre: 'Alejandro',
      apellidos: 'Velasquez',
      cargo: 'Coordinador Desarrollo Humano',
      fecha: '31/07/2019',
      dia: '31',
      foto: './assets/images/personal/alejandro.png'
    }, */

    /* {
      nombre: 'Santiago',
      apellidos: 'Carvajal',
      cargo: 'Profesional de Apoyo',
      fecha: '02/08/2019',
      dia: '02',
      foto: './assets/images/personal/santiago.png'
    },
    {
      nombre: 'Luis',
      apellidos: 'Parrado',
      cargo: 'Coordinador Desarrollo',
      fecha: '06/08/2019',
      dia: '06',
      foto: './assets/images/personal/lepo.png'
    }, */
    /* {
      nombre: 'Ronald',
      apellidos: 'Rivera',
      cargo: 'Conductor',
      fecha: '23/08/2019',
      dia: '23',
      foto: './assets/images/personal/lepo.png'
    }, */
    /* {
      nombre: 'Daniela',
      apellidos: 'Gutierrez',
      cargo: 'Profesional de Propuestas',
      fecha: '24/08/2019',
      dia: '24',
      foto: './assets/images/personal/daniela.png'
    },

    {
      nombre: 'Mario',
      apellidos: 'Lee',
      cargo: 'Asistente de Tecnología',
      fecha: '31/08/2019',
      dia: '31',
      foto: './assets/images/personal/mario.png'
    }, */
    {
      nombre: 'Fernando',
      apellidos: 'Rivera',
      cargo: 'Profesional SIG',
      fecha: '29/09/2019',
      dia: '29',
      foto: './assets/images/personal/fernando.png'
    }
    /* {
      nombre: 'Felipe',
      apellidos: 'Villamil',
      cargo: 'Profesional de Apoyo',
      fecha: '16/07/2019',
      dia: '23',
      foto: './assets/images/personal/felipe.png'
    },
    {
      nombre: 'Adriana',
      apellidos: 'Arango',
      cargo: 'Profesional de Apoyo',
      fecha: '16/07/2019',
      dia: '23',
      foto: './assets/images/personal/adriana.png'
    },
    {
      nombre: 'Octavio',
      apellidos: 'Arango',
      cargo: 'Profesional de Apoyo',
      fecha: '16/07/2019',
      dia: '23',
      foto: './assets/images/personal/octavio.png'
    },
    {
      nombre: 'Giovanni',
      apellidos: 'Gomez',
      cargo: 'Profesional de Apoyo',
      fecha: '16/07/2019',
      dia: '23',
      foto: './assets/images/personal/gio.png'
    } */
  ]

  monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  date = new Date()


  constructor(private dataService: DataService) {
    this.month = this.monthNames[this.date.getUTCMonth()];
    this.year = this.date.getFullYear()

  }

  sayHello() {
    this.dataService.getData().subscribe(data => {
      console.log(data)
    })
  }

  ngOnInit() {
    /* $('.svg-inject').svgInject(); */
  }
}