import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { Page404Component } from './page404/page404.component'
import { HseqComponent } from './hseq/hseq.component'
import { DirectorioComponent } from './directorio/directorio.component';
import { GaleriaComponent } from './galeria/galeria.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'hseq',
    component: HseqComponent
  },
  {
    path: 'directorio',
    component: DirectorioComponent
  },
  { path: '**', component: Page404Component }]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
